import React from "react"
import Layout from "../components/Layout"
import Block from "../components/ui/Block"
import FormGroup from "../components/ui/FormGroup"

export default function EmailSent() {
  const pageContext = {
    seo: {
      title: "Turnverein Lahr",
      description:
        "Der Turnverein Lahr von 1846 e.V. zählt zu den ältesten Turnvereinen in Baden- Württemberg und ist mit nahezu 1700 Mitgliedern der größte Sport anbietende Verein in Lahr.",
      robots: ["noindex, nofollow"],
      path: "/email-gesendet",
      lang: "de",
    },
  }
  return (
    <Layout pageContext={pageContext}>
      <Block
        note="Email gesendet"
        title="Deine Mail wurde an uns weitergeleitet."
        content="Vielen Dank für deine Kontaktaufnahme, wir werden uns zeitnah bei dir melden."
        textCenter
      />
    
    </Layout>
  )
}
